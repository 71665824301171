import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "wagr-text"
    }}><img parentName="h1" {...{
        "src": "https://contrast.now.sh/white/black?width=120&height=80&text=WAGR",
        "alt": "WAGR Text"
      }}></img></h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://members.iracing.com/membersite/member/LeagueView.do?league=3825"
        }}>{`iRacing League`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Robdel12/wearegoingracing"
        }}>{`GitHub`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://discord.gg/GxueMZA"
        }}>{`Discord`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      