import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/"
        }}>{`Home`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/series/f3/"
        }}>{`F3 Series`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/series/f3/results"
            }}>{`Season Results`}</a></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/rules"
        }}>{`Rules`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      